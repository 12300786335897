<template>
    <div>
        <section v-if="option == 'search-rooms'" class="main-c" :style="heightRoomGroup">

            <section v-if="!searchRooms.groupDetail">

                <!-- Display the group list -->

                <!-- show text if there is no room group saved -->
                <div class="mt-7"
                    v-if="searchRooms.displayList[0].list.length == 0 && searchRooms.displayList[1].list.length == 0">
                    <p class="text-h6">Não há salas disponíveis.</p>
                    <p>Adicione novas salas. As salas salvas aparecerão aqui.</p>
                </div>


                <div class="mt-7" v-for="(display, idx) in searchRooms.displayList" :key="idx">
                    <p v-if="display.list.length > 0" class="text-h6">{{ display.title }}</p>
                    <p v-if="display.list.length > 0 && idx == 0">Para editar, adicione todas as salas, faças as
                        alterações e salve o grupo de salas editadas.</p>
                    <v-data-table v-if="display.list.length > 0" :headers="searchRooms.headers" :items="display.list"
                        :items-per-page="-1" hide-default-footer hide-default-header class="elevation-1">
                        <template v-slot:item.delete="{ item }">
                            <!-- when clicked open list of rooms -->
                            <div v-if="!(selectAll || display.title === 'Salas do Centro')">
                                <v-icon small class="ml-1" @click="deleteRoomGroup(item.id)">
                                    mdi-delete
                                </v-icon>
                            </div>

                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- when clicked open list of rooms -->
                            <v-btn small dark class="mr-2 blue" @click="getRoomGroup(item.id)">
                                Ver Salas
                                <v-icon small class="ml-1">
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>

                        </template>
                    </v-data-table>
                </div>

            </section>

            <section v-if="searchRooms.groupDetail">
                <!-- display group of rooms -->
                <div class="mt-5">
                    <p class="text-h6 float-left">{{ searchRooms.detailList.title }}</p>
                    <p class="text-subtitle-1 float-left ml-5 mt-1">{{ searchRooms.detailList.name }}</p>
                    <p class="text-caption float-left ml-5 mt-2">{{ searchRooms.detailList.date }}</p>
                    <p>.</p>
                </div>

                <!-- Search box -->
                <div :class="`${isMobile ? 'search-m' : 'search'} pt-2 mb-2`">
                    <v-text-field v-model="search" append-icon="mdi-magnify" dense label="Pesquisar" outlined
                        hide-details></v-text-field>
                </div>

                <p v-if="searchRooms.validate" class="color-red">Selecione ao menos uma sala!</p>

                <!-- List of rooms -->
                <v-data-table v-model="searchRooms.selected" :show-select="!selectAll" :search="search"
                    :headers="searchRooms.roomsHeaders" :items-per-page="-1" :items="exibRooms" item-key="idx"
                    hide-default-footer class="elevation-1">
                    <!-- allow search without special characters -->
                    <template v-slot:item.all="{ item }">
                        <p class="mb-0">{{ item.bloco }}
                        </p>
                    </template>
                    <template v-slot:item.acessivel="{ item }">
                        <p class="mb-0">
                            <v-icon v-if="item.acessivel" color="blue">mdi-wheelchair</v-icon> {{
                                item.acessivel ? '' :
                                'Não'
                            }} Acessível
                        </p>
                    </template>
                </v-data-table>
                <!-- Add and Cancel buttons -->
                <div class="btn-add">
                    <v-btn text class="mr-4" @click="() => handleAction('close')">cancelar</v-btn>
                    <v-btn v-if="selectAll" dark class="blue" @click="sendGroup">Adicionar Grupo</v-btn>
                    <v-btn v-else dark class="blue" @click="sendSelected">Adicionar Selecionadas</v-btn>
                </div>
            </section>
        </section>


        <section v-if="option == 'search-classes'">

            <v-row v-if="!isMobile || !searchClasses.mobile.showResult" class="mt-1">

                <v-col cols="12" md="1">
                    <v-btn @click="() => change = !change" color="blue" dark><v-icon>mdi-swap-horizontal</v-icon></v-btn>
                </v-col>

                <v-col v-if="!change" cols="12" md="2">
                    <v-select dense v-model="searchClasses.input.nivelDeEnsino" :items="searchClasses.select.nivelDeEnsino"
                        label="Nível de Ensino" item-text="n" item-value="v" outlined></v-select>
                </v-col>

                <v-col v-if="change" cols="12" md="2">
                    <v-text-field dense outlined label="Código da Disciplina" v-model="searchClasses.input.codigo">
                    </v-text-field>
                </v-col>

                <v-col v-if="change" cols="12" md="2">
                    <v-text-field dense outlined label="Nome da Disciplina" v-model="searchClasses.input.nome">
                    </v-text-field>
                </v-col>

                <v-col v-if="!change" cols="12" :md="change ? '3' : '4'">
                    <v-select dense v-model="searchClasses.input.unidade" :items="departamentsList" item-text="name"
                        item-value="id" label="Unidade" outlined></v-select>
                </v-col>

                <v-col v-if="!change" cols="12" :md="change ? '1' : '2'">
                    <v-text-field dense outlined type="number" label="Ano" v-model="searchClasses.input.ano">
                    </v-text-field>
                </v-col>
                <v-col v-if="!change" cols="12" :md="change ? '2' : '1'">
                    <v-select dense v-model="searchClasses.input.periodo" :items="[1, 2, 3, 4]" label="Período" outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="1">
                    <v-btn @click="getClassList" color="blue" dark>buscar</v-btn>
                </v-col>
            </v-row>

            <Loading v-if="loading" text="Buscando por Turmas" />
            <p v-if="searchClasses.validate" class="color-red">VERIFIQUE OS DADOS E TENTE NOVAMENTE!</p>
            <p v-if="searchClasses.notFound" class="color-red">NÃO ENCONTRADO, VERIFIQUE OS DADOS E TENTE NOVAMENTE.</p>

            <div v-if="searchClasses.classList.length > 0" class="main-c" :style="heightClassGroup">
                <!-- Search box -->
                <div :class="`${isMobile ? 'search-m' : 'search'} pt-2 mb-2`">
                    <v-text-field v-model="search" append-icon="mdi-magnify" dense label="Pesquisar" outlined
                        hide-details></v-text-field>
                </div>
                <p v-if="searchClasses.validateSelect" class="color-red">Selecione ao menos uma turma!</p>
                <!-- List of classes -->
                <v-data-table v-model="searchClasses.selected" show-select :search="search" :headers="searchClasses.headers"
                    :items-per-page="-1" :items="exibClass" item-key="idx" hide-default-footer class="elevation-1">

                    <!-- allow search without special characters -->
                    <template v-slot:item.all="{ item }">
                        <p class="mb-0">{{ item.codigo }}
                        </p>
                    </template>

                    <template v-for="header in searchClasses.headers" v-slot:[`header.${header.value}`]>
                        {{ header.text == 'PCD' ? '' : header.text }}
                        <div :key="header.value" v-if="header.text == 'PCD'" class="mb-n4">
                            <v-icon>mdi-wheelchair</v-icon>
                            <HelpIcon v-if="header.helpText != undefined" small :text="header.helpText" />
                        </div>
                    </template>

                    <template v-slot:item.pcd="{ item }">
                        <p class="mb-0">
                            <v-icon v-if="item.pcd" color="blue">mdi-wheelchair</v-icon> {{ item.pcd ? '' : '-' }}
                        </p>
                    </template>

                </v-data-table>
                <!-- Add and Cancel buttons -->
                <div class="btn-add">
                    <v-btn text class="mr-4" @click="() => handleAction('close')">cancelar</v-btn>
                    <v-btn dark class="blue" @click="sendClasses">Adicionar Selecionadas</v-btn>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import { getRoomGroupList, getRoomGroup, deleteRoomGroup } from '@/api/db';
import { findClasses } from "@/api/paas";
import { departaments } from '@/api/util';
import latinize from 'latinize';

import HelpIcon from '../HelpIcon.vue';
import Loading from '../Loading.vue';

export default {
    name: "PaasSearchOption",
    components: {
        HelpIcon,
        Loading,
    },
    props: {
        option: String,
        handleAction: Function,
        add: Function,
        addDep: Function,
        addRoomTypes: Function,
        setHeight: Number,
        selectAll: Boolean //used to select a group of rooms
    },
    data() {
        return {
            change: false,
            search: '',
            selected: [],
            selectedRoomGroup: undefined,
            departamentsList: [],
            loading: false,
            searchRooms: {
                validate: false,
                groupDetail: false,
                detailList: [],
                selected: [],
                displayList: [
                    //private list
                    { title: 'Minhas Salas', list: [] },
                    //public list
                    { title: 'Salas do Centro', list: [] }
                ],
                headers: [
                    { text: 'Delete', align: 'start', value: 'delete' },
                    { text: 'Título', value: 'name' },
                    { text: 'Nome', value: 'create_user' },
                    { text: 'Data', value: 'create_date' },
                    { text: 'acao', value: 'actions', align: 'end' },
                ],
                roomsHeaders: [
                    { text: 'Bloco', align: 'start', value: 'all' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Capacidade', value: 'capacidade' },
                    { text: 'Tipo de Sala', value: 'tipo' },
                    { text: 'Preferências', value: 'preferencias', sortable: false, },
                    { text: 'Acessibilidade', value: 'acessivel' },
                    { text: 'Exceção', value: 'excecao', sortable: false },
                ]
            },
            searchClasses: {
                mobile: {
                    showResult: false,
                },
                selected: [],
                notFound: false,
                validate: false,
                validateSelect: false,
                classList: [],
                input: {
                    nivelDeEnsino: undefined,
                    unidade: undefined,
                    ano: undefined,
                    periodo: undefined,
                    codigo: '',
                    nome: '',

                },
                select: {
                    nivelDeEnsino: [
                        { n: 'Graduação', v: 'G' },
                        { n: 'Mestrado', v: 'E' },
                        { n: 'Doutorado', v: 'D' }
                    ],
                },
                headers: [
                    { text: 'Código', align: 'start', value: 'all' },
                    { text: 'Turma', value: 'turma', sortable: false, },
                    { text: 'Período', value: 'periodo' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Horário', value: 'horario', sortable: false, },
                    { text: 'Alunos', value: 'alunos' },
                    { text: 'Docente', value: 'docente', sortable: false, },
                    { text: 'PCD', value: 'pcd', helpText: 'Há discente com mobilidade redusida ou usuário de cadeira de rodas.' },
                ]
            }
        }

    },
    computed: {
        height() {
            return this.setHeight ? this.setHeight : this.$vuetify.breakpoint.height <= 720 ? 720 : this.$vuetify.breakpoint.height;
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 570;
        },
        heightRoomGroup() {
            return this.setHeight ? `height: ${this.setHeight}px` : this.isMobile ? 'height: 620px' : `height: ${this.height - 300}px;`;
        },
        heightClassGroup() {
            return `height: ${this.height - 370}px;`;
        },
        exibClass() {
            return this.searchClasses.classList.map(e => {

                let dataLatinaze = latinize(`${e.nome} ${e.docente}`);
                let all = `${e.codigo} ${e.docente} ${e.nome} ${dataLatinaze}`;

                return {
                    ...e,
                    idx: e.codigo + '-' + e.turma + '-' + e.periodo,
                    all: all,
                }
            })
        },
        // format list of rooms to exib
        exibRooms() {
            return this.searchRooms.detailList.rooms.map((e, idx) => {

                //allow search without special characters
                let dataLatinaze = latinize(`${e.bloco} ${e.nome} ${e.tipo}`);
                let all = `${e.bloco} ${e.nome} ${e.tipo} ${dataLatinaze}`;

                return {
                    idx: idx,
                    bloco: e.bloco,
                    nome: e.nome,
                    capacidade: e.capacidade,
                    tipo: e.tipo,
                    acessivel: e.acessivel,
                    //show preferences in descending order
                    preferencias: e.preferencias.map(e => e.value).every(e => e == 1) ? 's/ preferências' : (e.preferencias.sort((a, b) => b.value - a.value)).map((el) => el.name).toString(),
                    excecao: e.excecao == '' || e.excecao == undefined || e.excecao == 'undefined' ? 's/ exceção' : e.excecao,
                    all: all,
                }
            })
        }
    },
    mounted() {
        this.departamentsList = departaments();
    },
    created() {
        // if it is search room tab, initialize with a resquest of a list of room groups 
        if (this.option == 'search-rooms') {
            this.getRoomGroupList();
        }

    },
    methods: {
        //get a list of availables room groups, public and user only
        async getRoomGroupList() {
            //send request to backend
            const res = await getRoomGroupList();
            //saves user only group of rooms
            this.searchRooms.displayList[0].list = res.userRooms;
            //saves public group of rooms
            this.searchRooms.displayList[1].list = res.publicRooms
        },
        //get a list rooms with specific id
        async getRoomGroup(id) {

            this.selectedRoomGroup = id;
            // send request to backend
            let res = await getRoomGroup(id);
            // saves list
            this.searchRooms.detailList = res;
            // opens detail tab
            this.searchRooms.groupDetail = true;
        },

        async deleteRoomGroup(id) {
            await deleteRoomGroup(id).then((res) => {
                if (res.status == 200)
                    this.getRoomGroupList();
            })
        },

        sendSelected() {

            if (this.searchRooms.selected.length > 0) {
                //create a array with selected rooms and adds it to the instance input
                const selected = this.searchRooms.selected.map((el) => this.searchRooms.detailList.rooms[el.idx]);

                //add selected
                this.add(selected);

                //find all departaments 
                let deptos = []
                selected.forEach(e => {
                    e.preferencias.forEach(el => {
                        if (deptos.indexOf(el.name) == -1)
                            deptos.push(el.name)
                    })
                });

                //add departaments
                this.addDep(deptos);

                //find all roomTypes
                let roomTypes = [];
                selected.forEach(e => {
                    if (roomTypes.indexOf(e.tipo) == -1)
                        roomTypes.push(e.tipo);
                })

                //add Room Types
                this.addRoomTypes(roomTypes);


                //close the search room tab
                this.handleAction('close');
            } else {
                //if none selected, display a warning
                this.searchRooms.validate = true;
            }

        },
        sendGroup() {
            this.add(this.selectedRoomGroup);
        },
        sendClasses() {
            if (this.searchClasses.selected.length > 0) {
                //adds it to the instance input
                this.add(this.searchClasses.selected);
                this.addDep([this.searchClasses.selected[0].departamento])
                //close the search classes tab
                this.searchClasses.mobile.showResult = false;
                this.handleAction('close');
            } else {
                //if none selected, display a warning
                this.searchClasses.validateSelect = true;
            }
        },
        async getClassList() {
            this.loading = true;
            this.searchClasses.classList = [];
            this.searchClasses.mobile.showResult = true;

            if ((!this.change && this.searchClasses.input.nivelDeEnsino == undefined)
                ||
                (!this.change && (
                    this.searchClasses.input.periodo == undefined
                    || this.searchClasses.input.ano == undefined
                    || this.searchClasses.input.ano > (new Date().getFullYear()) + 1
                    || this.searchClasses.input.ano < 2010
                ))
                || (!this.change && this.searchClasses.input.unidade == undefined)
                || (this.change && !(this.searchClasses.input.codigo != '' || this.searchClasses.input.nome != ''))
            ) {
                this.searchClasses.validate = true;
                this.searchClasses.classList = [];
            } else {
                this.searchClasses.validate = false;

                if (!this.change) {
                    //deafut search
                    this.searchClasses.input.codigo = '';
                    this.searchClasses.input.nome = '';
                } else {
                    //search by name or code
                    this.searchClasses.input.unidade = '';
                    this.searchClasses.input.nivelDeEnsino = '';
                }

                const res = await findClasses(this.searchClasses.input.nivelDeEnsino, this.searchClasses.input.unidade,
                    this.change ? undefined : this.searchClasses.input.ano, this.change ? undefined : this.searchClasses.input.periodo, this.searchClasses.input.codigo, this.searchClasses.input.nome);
                if (res == 'not found') {
                    this.searchClasses.notFound = true;
                } else {
                    this.searchClasses.notFound = false;
                    this.searchClasses.classList = res;
                }

            }
            this.loading = false;
        }
    }
}

</script>

<style scoped>
.main-c {
    clear: both;
    overflow: hidden;
    overflow-y: auto;
}

.search {
    width: 30%;
}


.search-m {
    width: 100%;
}

.btn-add {
    position: absolute;
    bottom: 70px;
    right: 90px;
}

.color-red {
    color: red;
}
</style>