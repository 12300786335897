export const modulePoh = {
  state: {
    studentData: "none",
    curriculumData: "none",
    /** @type Array<{hash: String, status: String, date: Date}> */
    historicoWaitList: [],
    updatesPOH: [],
    studentDataStatus: 'ready',
  },

  actions: {
    addToHistoricoPOH({ state, getters, rootGetters }, payload) {
      
      const now = new Date();
      const date = `${now.toLocaleDateString(
        "pt-BR"
      )} ${now.toLocaleTimeString("pt-BR")}`;

      // Try to find equal plan
      const index = state.historicoWaitList.findIndex(
        el => el.hash === payload.hash
      );

      // If an equal plan is already in array, only update date
      if (
        index >= 0 &&
        state.historicoWaitList[index].user === rootGetters.getIdVinculo
      ) {
        state.historicoWaitList[index].date = date;
      }
      // Else add new item
      else {
        const id =
          getters.getHistoricoPOH.length === 0
            ? 1
            : getters.getHistoricoPOH[0].id + 1;

        state.historicoWaitList.unshift({
          id,
          hash: payload.hash,
          status: payload.status,
          numTakenSemesters: payload.numTakenSemesters,
          date: date,
          user: rootGetters.getIdVinculo,
          numClasses: payload.numClasses,
        });
      }
    },

    removeFromHistoricoPOH({ state, rootGetters }, payload) {
      const foundIndex = state.historicoWaitList.findIndex(
        el =>
          el.hash == payload.hash &&
          el.user == rootGetters.getIdVinculo
      );
      if (foundIndex > -1) state.historicoWaitList.splice(foundIndex, 1);
    },

    setUpdatePohDisplay({ state, rootGetters }) {

      const update = '2024.1.0-poh';
      const userId = rootGetters.getIdVinculo;

      state.updatesPOH.push({update, userId});

    },

  },

  mutations: {
    setPohData(state, payload) {
      state.studentData = payload.studentData;
      state.curriculumData = payload.curriculumData;
    },
    updateHistoricoPOH(state, payload) {
      for (let i = 0; i < state.historicoWaitList.length; i++) {
        if (state.historicoWaitList[i].hash == payload.hash) {
          // Can't set directly at index because Vue can't detect this kind of change
          // see https://vuejs.org/v2/guide/reactivity.html#For-Arrays
          state.historicoWaitList.splice(i, 1, payload);
        }
      }
    },
    updateWaitingPlansPOH(state, payload) {
      state.waitingPlans = payload;
    },

    setStudentDataStatus(state, payload) {

      // status:
      //   'waiting'
      //   'ready'
      //   'error'

      state.studentDataStatus = payload;
    }
  },

  getters: {
    getStudentData: state => {
      return state.studentData;
    },
    getHistoricoPOH: (state, _getters, _rootState, rootGetters) => {
      return state.historicoWaitList.filter(
        el => rootGetters.getIdVinculo === el.user
      );
    },
    getWaitingPOH: state => {
      let n = 0;
      state.historicoWaitList.forEach(element => {
        if (element.status === "queue") {
          n++;
        }
      });
      return n;
    },
    getUpdatePohDisplay: (state, _getters, _rootState, rootGetters) => {

      const update = '2024.1.0-poh';

      let found = state.updatesPOH.filter((e) => e.update == update && e.userId == rootGetters.getIdVinculo);

      return found.length == 0;

    },
    getStudentDataStatus: (state) => {
      return state.studentDataStatus;
    }
  }
};
