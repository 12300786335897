<template>
    <div>
        <section v-if="type == 'poh'">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Boas Vindas!
                </v-card-title>

                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title>O que é o módulo de Otimização de Horários (POH)?
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-card-text class="mt-n8">
                    O POH tem por objetivo determinar quais disciplinas um discente deve cursar ao longo dos próximos
                    períodos letivos de forma que a pessoa possa se graduar o mais rápido possível.
                </v-card-text>

                <v-card-text class="mt-n6">
                    O POH leva em consideração o histórico do discente, bem como pré-requisitos e choques de horário das
                    disciplinas, entre muitas outras coisas. Além disso, é possível que alguns parâmetros como
                    carga-horária e número de créditos (entre outros) sejam customizados a fim de se gerar planos
                    (distribuições de disciplinas) que atendam as preferências do discente. O discente pode gerar vários
                    planos diferentes (considerando diferentes configurações) e escolher aquele que mais o agrada.
                </v-card-text>

                <v-card-text class="mt-n6">
                    Experimente mudar os parâmetros disponíveis no simulador e abra a lista de disciplinas. Você pode
                    determinar diversas opções para cada disciplina!
                </v-card-text>

                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title>Sobre a última atualização:</v-list-item-title>
                        <v-list-item-subtitle>
                            Nos ajude informando se encontrar algum bug, ou se tiver alguma sugestão.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title>Principais mudanças:</v-list-item-title>
                        <v-list-item-subtitle>
                            - Os dados das disciplinas foram atualizados para o semestre 2024.1.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            - As alterações feitas nas disciplinas permanecerão salvas no navegador. Os dados serão atualizados ao clicar em 'restaurar dados' ou quando houver uma nova atualização.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            - As soluções podem apresentar uma melhor distribuição das disciplinas entre os períodos.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            - Os planos podem ser encontrados em diferentes dispositivos.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            - Os planos podem ser renomeados.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-card-text >
                    <b>Vale lembrar que o SACI não realiza matrícula!</b>
                </v-card-text>

                <v-card-text class="mt-n6">
                    Antes de iniciar, recomendamos ler o FAQ, com as perguntas e respostas frequentes.
                    <span class="saiba-mais" @click="openFaq">Ir ao FAQ.</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="action">
                        Entendi
                    </v-btn>
                </v-card-actions>
            </v-card>

        </section>

        <section v-if="type == 'paas'"></section>
    </div>
</template>

<script>
export default {
    name: 'UpdateCard',
    props: {
        type: String,
        action: Function,
    },
    methods: {
        openFaq() {
            this.$router.push({ path: "/faq" });
        }
    }
}
</script>

<style scoped>
.saiba-mais {
    color: #1565C0;
    cursor: pointer;
}
</style>

