<template>
    <div>
        <Header title="Solicitar Turmas" />
        <section class="px-11 mb-11">

            <div v-if="requests.length == 0 && classes.length == 0">
                <NotFound type="team" btn="Criar Solicitação" :btnFunction="() => dialog.open = true"
                    title="Não há solicitações."
                    text="Aqui você pode criar solicitações de turmas. Com o link gerado, outros servidores poderão buscar, adicionar e editar novas turmas que estarão disponíveis aqui para futuras simulações." />
            </div>

            <div v-else>

                <div v-if="dialog.setRooms" class="setRooms">
                    <div class="pointer" @click="() => dialog.setRooms = false">
                        <v-icon color="blue" class="pt-n2 ml-8 float-left">mdi-arrow-left</v-icon>
                        <p class="float-left ml-2">voltar</p>
                    </div>

                    <div class="setRoomCard">
                        <p class="text-subtitle-2">Escolher grupo de salas</p>
                        <p class="text-caption mt-n2 mb-n2">Ao associar um grupo de salas, a pessoa solicitada pode
                            especificar as preferências por tipo de
                            sala, além de configurar as preferências de salas por departamento.</p>
                    </div>
                    <v-btn v-if="roomGroup != null" class="btn2 mb-4 float-left mt-3" color="blue" outlined
                        @click="() => handleEditRoomGroup(null)">
                        Remover Grupo de salas
                        <v-icon rigth small>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                    <PaasSearchOption :add="handleEditRoomGroup" option="search-rooms"
                        :handleAction="(e) => dialog.setRooms = false" :setHeight="height" selectAll />
                </div>

                <div v-else-if="openSelectRequests">
                    <v-btn plain small class="mb-3" @click="() => openSelectRequests = false">
                        <v-icon small color="blue">
                            mdi-arrow-left
                        </v-icon>
                        Voltar
                    </v-btn>
                    <AssistentCard class="ml-0"
                        text="Antes de prosseguir, selecione quais solicitações serão consideradas:" />

                    <v-btn class="btn mt-4 mb-10" color="blue" :outlined="openSimulation" dark @click="openSimulador">
                        <v-progress-circular :width="2" :size="20" v-if="openSimulation" indeterminate
                            color="primary"></v-progress-circular>
                        <p class="my-0 pl-2">
                            {{ openSimulation
                                ? 'Carregando informações e redirecionando... ' : 'Ir para simulador' }}
                        </p>
                    </v-btn>

                    <p v-if="selectWarning" class="mt-10 color-red">Você não selecionou nenhuma solicitação!</p>

                    <v-data-table showSelect class="clear-all" :items-per-page="-1" hide-default-footer
                        :headers="headers.selectRequests" :items="requestList" v-model="selectRequests" item-key="link">
                        <!-- Add help icons next to headers -->
                        <template v-slot:item.status="{ item }">
                            <p class="pt-2">
                                <v-icon v-if="item.status == 'Pronto'" class="icon-green" small>
                                    mdi-checkbox-marked-circle
                                </v-icon>
                                <v-icon v-else small>
                                    mdi-clock-time-four
                                </v-icon>
                                {{ item.status }}
                            </p>
                        </template>
                    </v-data-table>

                </div>

                <div v-else>
                    <div v-if="copy.show" class="copy" :style="copyStyle">
                        <p class="ma-0 pa-0 text-overline">Copiado!</p>
                    </div>

                    <v-btn v-if="roomGroup == null" class="btn2 mb-4" color="blue" outlined
                        @click="() => dialog.setRooms = true">Associar
                        Salas</v-btn>
                    <v-btn v-else class="btn2 mb-4" color="blue" outlined @click="() => dialog.setRooms = true">Grupo de
                        Salas: {{ roomGroup }}
                        <v-icon rigth small>
                            mdi-pencil
                        </v-icon>
                    </v-btn>

                    <v-btn class="blue btn" dark @click="() => dialog.open = true">Criar Solicitação</v-btn>

                    <div class="clear-all">
                        <p>Solicitações</p>
                        <v-data-table :items-per-page="-1" hide-default-footer :headers="headers.requests"
                            :items="requestList">
                            <!-- Add help icons next to headers -->
                            <template v-for="header in headers.requests" v-slot:[`header.${header.value}`]>
                                {{ header.text == 'delete' ? '' : header.text }}
                            </template>
                            <template v-slot:item.delete="{ item }">
                                <v-icon small @click="handleDelete(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.link="{ item }">
                                <p v-if="item.status != 'Pronto'" class="pt-2 pointer" @click="showAlert"
                                    v-clipboard:copy="item.link">
                                    <v-icon small>
                                        mdi-content-copy
                                    </v-icon>

                                    {{ item.link.slice(0, 50) }}...
                                </p>
                                <v-btn v-else small outlined color="blue"
                                    @click="() => handleActivateRequest(item)">reativar</v-btn>
                            </template>
                            <template v-slot:item.status="{ item }">

                                <p class="pt-2">

                                    <v-icon v-if="item.status == 'Pronto'" class="icon-green" small>
                                        mdi-checkbox-marked-circle
                                    </v-icon>

                                    <v-icon v-else small>
                                        mdi-clock-time-four
                                    </v-icon>

                                    {{ item.status }}

                                </p>
                            </template>
                        </v-data-table>
                    </div>

                    <div v-if="classes.length != 0">

                        <v-btn class="btn mt-15" color="blue" dark @click="() => openSelectRequests = true">
                            <p class="my-0 pl-2">Nova simulação com turmas adicionadas</p>
                        </v-btn>

                        <div class="clear-all mb-10">
                            <p>Turmas Adicionadas</p>

                            <v-data-table :items-per-page="-1" hide-default-footer :headers="headers.classes"
                                :items="exibClasses">
                                <!-- Add help icons next to headers -->
                                <template v-for="header in headers.requests" v-slot:[`header.${header.value}`]>
                                    {{ header.text == 'delete' ? '' : header.text }}
                                </template>
                                <template v-slot:item.pcd="{ item }">
                                    <p class="mb-0">
                                        <v-icon v-if="item.pcd" color="blue">mdi-wheelchair</v-icon> {{
                                            item.pcd ?
                                            '' : '-'
                                        }}
                                    </p>
                                </template>
                                <template v-slot:item.delete="{ item }">
                                    <v-icon small @click="deleteOneClass(item.id)">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <template v-slot:item.link="{ item }">

                                    <p class="pt-2 pointer" @click="showAlert" v-clipboard:copy="item.link">
                                        <v-icon small>
                                            mdi-content-copy
                                        </v-icon>

                                        {{ item.link.slice(0, 50) }}...
                                    </p>
                                </template>
                                <template v-slot:item.status="{ item }">

                                    <p class="pt-2">

                                        <v-icon v-if="item.status == 'Pronto'" class="icon-green" small>
                                            mdi-checkbox-marked-circle
                                        </v-icon>

                                        <v-icon v-else small>
                                            mdi-clock-time-four
                                        </v-icon>

                                        {{ item.status }}
                                    </p>
                                </template>
                            </v-data-table>



                        </div>
                    </div>



                </div>

            </div>
            <v-dialog v-model="dialog.open" max-width="500">
                <v-card class="pa-4">
                    <v-card-title class="pl-0 pb-6 text-h6">
                        Nomeie esta solicitação
                    </v-card-title>
                    <v-text-field outlined label="Nome" v-model="name"></v-text-field>
                    <v-btn class="blue" dark @click="createRequest">Criar</v-btn>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog.delete" max-width="500">
                <v-card class="pa-4">
                    <v-card-title class="pl-0 pb-6 text-h6">
                        Remover turmas?
                    </v-card-title>
                    <v-card-text class="ml-n4">
                        Ao clicar em deletar, as turmas adicionadas nesta solicitação também serão removidas.
                    </v-card-text>
                    <v-card-actions class="pa-0 pt-7">
                        <v-btn small class="blue" dark @click="() => handleDeleteRequest(dialog.deleteItem)">Deletar</v-btn>
                        <v-btn samll plain @click="() => dialog.delete = false">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog.link" max-width="500">
                <v-card class="pa-4">
                    <v-card-title class="pl-0 pb-6 text-h6">
                        Reativar solicitação?
                    </v-card-title>
                    <v-card-text class="ml-n4">
                        O link estará novamente disponível. A pessoa com o link poderá remover as turmas já
                        adicionadas, e
                        adicionar novas turmas.
                    </v-card-text>
                    <v-card-actions class="pa-0 pt-7">
                        <v-btn small class="blue" dark @click="activateRequest">Reativar</v-btn>
                        <v-btn small plain @click="() => dialog.link = false">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </section>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import NotFound from '@/components/NotFound.vue';
import PaasSearchOption from '@/components/paas/PaasSearchOption.vue';
import AssistentCard from '@/components/AssistentCard.vue';

import {
    getRequestsAndClasses, createPaasRequest, deletePaasRequest, deletePaasClassesRequest, deleteOnePaasClassRequest, resetRequest, editRoomGroupRequest,
    getRoomGroup
} from "@/api/db";
import { handleNewSolutionRequests, validateClasses } from '@/api/paas';



export default {
    name: "PaasRequest",
    components: {
        Header,
        NotFound,
        PaasSearchOption,
        AssistentCard
    },
    data() {
        return {
            requests: [],
            classes: [],
            roomGroup: null,
            roomGroupId: null,
            name: '',
            dialog: {
                open: false,
                delete: false,
                setRooms: false,
                link: false,
                deleteItem: {},
            },
            copy: {
                show: false,
                x: 0,
                y: 0
            },
            openSimulation: false,
            openSelectRequests: false,
            selectRequests: [],
            selectWarning: false,
            headers: {
                requests: [
                    {
                        text: 'Nome',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Data', value: 'date' },
                    { text: 'Link', value: 'link' },
                    { text: 'Status', value: 'status' },
                    { text: 'Adicionado por', value: 'author' },
                    { text: 'delete', value: 'delete' }
                ],
                selectRequests: [
                    {
                        text: 'Nome',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Data', value: 'date' },
                    { text: 'Status', value: 'status' },
                    { text: 'Adicionado por', value: 'author' },
                ],
                classes: [
                    { text: 'Solicitação', value: 'requestName', align: 'start' },
                    { text: 'Código', value: 'codigo' },
                    { text: 'Turma', value: 'turma' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Docente', value: 'docente', },
                    { text: 'Departamento', value: 'departamento', },
                    { text: 'PCD', value: 'pcd' },
                    { text: 'Preenchido por', value: 'author' },
                    { text: 'delete', value: 'delete' }
                ]
            },
        };
    },
    computed: {
        roomsTableHeight() {
            return this.isMobile ? 'height: 620px' : `height: ${this.height - 300}px;`;
        },
        height() {
            return this.$vuetify.breakpoint.height - 400;
        },
        requestList() {
            return this.requests.map(e => {
                return {
                    ...e,
                    link: `https://sa.ci.ufpb.br/paas/solicitacao/${e.hash}`,
                }
            })
        },
        exibClasses() {
            return this.classes.map((e, idx) => {
                return {
                    ...e,
                    id: e.codigo + '-' + e.turma,
                    idx: idx,
                    preferencias: this.roomGroup != null ? e.preferencias != null ? e.preferencias.map(e => e.value).every(e => e == 1) ? 's/ preferências' : (e.preferencias.sort((a, b) => b.value - a.value))[0].name : "s/ preferências" : "",
                }
            })
        },
        copyStyle() {
            return {
                top: `${this.copy.y}px`,
                left: `${this.copy.x}px`
            }
        }
    },
    methods: {
        showAlert(e) {
            this.copy.x = e.clientX - 30;
            this.copy.y = e.clientY - 15;
            this.copy.show = true;
            setTimeout(() => {
                this.copy.show = false;
            }, 1000)
        },
        async createRequest() {
            if (this.name.length >= 3) {
                let requests = await createPaasRequest(this.name);
                this.requests = JSON.parse(JSON.stringify(requests));
                this.dialog.open = false;
                this.name = '';
            }
        },
        async handleDelete(item) {
            if (this.classes.length == 0 || this.classes.filter(e => e.hash == item.hash).length == 0) {
                let requests = await deletePaasRequest(item.hash);
                this.requests = JSON.parse(JSON.stringify(requests));
                this.dialog.delete = false;
            } else {
                this.dialog.deleteItem = JSON.parse(JSON.stringify(item));
                this.dialog.delete = true;
            }
        },
        async handleDeleteRequest(item) {
            //delete classes associated    
            let classes = await deletePaasClassesRequest(item.hash);
            this.classes = JSON.parse(JSON.stringify(classes));
            //delete classes 
            let requests = await deletePaasRequest(item.hash);
            this.requests = JSON.parse(JSON.stringify(requests));
            //close dialog
            this.dialog.delete = false;
        },
        //reset request link
        handleActivateRequest(e) {
            this.dialog.link = true;
            this.dialog.request = e;
        },
        async activateRequest() {
            this.dialog.link = false;
            let res = await resetRequest(this.dialog.request.hash);
            this.requests = JSON.parse(JSON.stringify(res));
        },
        async deleteOneClass(id) {
            let classes = await deleteOnePaasClassRequest(id);
            this.classes = JSON.parse(JSON.stringify(classes));
        },
        async handleEditRoomGroup(id) {
            let res = await editRoomGroupRequest(id);
            if (id == null) {
                this.roomGroup = null;
                this.roomGroupId = null;
            }
            else {
                this.roomGroup = res;
                this.roomGroupId = id;
            }
            this.dialog.setRooms = false;
        },
        openSimulador() {
            if (this.selectRequests.length > 0) {
                this.openSimulation = true;

                //select classes from requests
                let classes = []
                this.selectRequests.forEach(e => {
                    let filter = this.classes.filter(el => el.hash == e.hash);
                    classes.push(...filter);
                });

                if (this.roomGroupId == null) {
                    classes = validateClasses(classes, [])
                    handleNewSolutionRequests(classes, []);
                    this.$router.push({ path: "/paas-simulador" });
                } else {
                    getRoomGroup(this.roomGroupId)
                        .then((rooms) => {
                            classes = validateClasses(classes, [])
                            handleNewSolutionRequests(classes, rooms.rooms);
                            this.$router.push({ path: "/paas-simulador" });
                        })
                        .catch(er => {
                            console.log('error:', er);
                            alert('Algo deu errado, tente novamente em alguns instantes.');
                            this.openSimulation = false;
                        })
                }


            } else {
                this.selectWarning = true;
            }

        }
    },
    created() {
        this.$store.commit("setCurrentComponent", {
            component: "Paas",
        });
    },
    async mounted() {

        let res = await getRequestsAndClasses();

        this.requests = res.links;
        this.classes = res.classes;
        this.roomGroup = res.roomGroup;
        this.roomGroupId = res.roomGroupId;
        //adiciona o nome da solicitacao a cada classes
        this.classes.forEach(e => {
            let found = this.requests.find(el => el.hash == e.hash)
            e.requestName = found.name;
        })

        console.log(this.classes);

        if (res.roomGroup != null) {
            this.headers.classes.splice(3, 0, {
                text: 'Preferências', value: 'preferencias', sortable: false,
            })
        }
    }
}
</script>

<style scoped>
.setRoomCard {
    width: 100%;
    clear: both;
    float: left;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.setRooms {
    height: 85vh;
}

.icon-green {
    color: #81C784;
}

.pointer {
    cursor: pointer;
}

.color-red {
    color: red;
}

.btn {
    float: right;
}

.clear-all {
    width: 100%;
    float: left;
    clear: both;
}

.copy {
    padding: 2px 10px;
    border-radius: 15px;
    background-color: #64B5F6;
    position: fixed;
    color: #eaeaea
}
</style>