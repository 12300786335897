<template>
  <div>

    <div v-if="type == 'primary'" class="card card-primary">
      <p class="title-1 font-weight-bold">{{ title }}</p>
      <p class="subtitle text-subtitle-1">{{ subtitle }}</p>
      <div class="arrow-box">
        <v-icon class="arrow-icon-white" large>
          mdi-arrow-right
        </v-icon>
      </div>

    </div>

    <div v-else-if="type == 'secondary'" class="card card-secondary">
      <p class="title-1 font-weight-bold">{{ title }}</p>
      <p class="subtitle text-subtitle-1">{{ subtitle }}</p>
      <div class="arrow-box">
        <v-icon class="arrow-icon" large>
          mdi-arrow-right
        </v-icon>
      </div>

    </div>

    <div v-else-if="type == 'basic'" class="card card-basic">
      <p class="title-1 font-weight-bold">{{ title }}</p>
      <p class="subtitle text-subtitle-1">{{ subtitle }}</p>
      <div class="arrow-box">
        <v-icon class="arrow-icon" large>
          mdi-arrow-right
        </v-icon>
      </div>
    </div>

    <v-card v-else dark class="card card-default ma-6">
      <p class="title-1 font-weight-bold">{{ title }}</p>
      <p class="subtitle text-subtitle-1">{{ subtitle }}</p>
    </v-card>

  </div>


</template>

<script>
export default {
  name: "LargeButton",
  props: {
    title: String,
    subtitle: String,
    type: String,
  },
};
</script>

<style scoped>
.title-1 {
  padding: 0 25px;
  font-size: 2vw;
}

.subtitle {
  margin-top: 30px;
  padding: 0 25px;
  font-size: 1.5vw;
  font-weight: 400;
}

.card {
  padding: 50px 0;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.card-default {
  position: relative;
  text-align: center;
  min-height: 300px;
  background-color: var(--v-accent-darken2);
}

.card-primary {
  position: relative;
  text-align: left;
  color: white;
  height: 420px;
  background-image: linear-gradient(135deg, #1491ff, #0346b6);
}

.card-default:hover {
  background-color: var(--v-primary-base);
}

.card-primary:hover {
  cursor: pointer;
  background: linear-gradient(45deg,#0088ff, #2f7dc2, #3b3dc9 , #0088ff);
    background-size: 600% 100%;
    animation: gradient 2s linear infinite;
    animation-direction: alternate;
}

@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}

.card-secondary {
  position: relative;
  text-align: left;
  height: 420px;
  background-color: var(--v-accent-base);
  border: rgba(109, 109, 109, 0.1) 3px solid;
}

.card-secondary:hover, .card-basic:hover {
  color: white;
  cursor: pointer;
  background: linear-gradient(45deg,#0088ff, #2f7dc2, #3b3dc9 , #0088ff);
    background-size: 600% 100%;
    animation: gradient 2s linear infinite;
    animation-direction: alternate;
}

.card-basic {
  position: relative;
  text-align: left;
  height: 420px;
  background-color: var(--v-accent-base);
}

.arrow-box {
  padding-right: 50px;
  width: 100%;
  text-align: right;
}

.arrow-icon,
.arrow-icon-white {
  position: absolute;
  bottom: 14px;
  /* margin: 200px 0 0 100px; */
}

.arrow-icon-white {
  position: absolute;
  color: white;
}

@media only screen and (max-width: 500px) {

  .card {
    width: 100%;
    min-height: 320px;
  }

  .title-1 {
    font-size: 6vw;
  }

  .subtitle {
    font-size: 5vw;
  }
}

@media only screen and (min-width: 1200px) {
  .title-1 {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1.3rem;
  }

  .card {
    min-height: 308px;
    width: 100%;
  }
}
</style>