<template>
  <div>
    <div v-if="loading" class="all-center">
      <Loading />
    </div>

    <div class="main">
      <section v-if="!loading" class="content pt-7">
        <div @click="goHome">
          <v-img class="ml-3 mb-4 pointer" width="150" src="../assets/logo-white.svg"></v-img>
        </div>

        <p class="text-h6 title">{{ info.centro }}</p>
        <div class="solution">
          <div v-if="found">
            <div :class="[isMobile ? 'px-0 pt-4' : 'pa-4']">
              <p class="mt-5 ml-8">{{ info.description }}</p>
              <PaasSolution :plan="solution" :initRoom="roomParam" />
              <p class="mt-6 all-center text-caption">Última atualização: {{ info.date }}</p>
            </div>
          </div>
          <div class="all-center pa-4" v-if="notPublished">
            <NotFound :title="info.centro" btn="Ir para home" :btnFunction="goHome"
              :text="`A alocação de aulas não está disponível. ${info.description}`" />
            <p class="mt-6">Última atualização: {{ info.date }}</p>
          </div>
          <div class="all-center pa-4" v-if="notFound">
            <NotFound title="Ops, não encontramos este centro"
              :text="`A alocação de aulas não está disponível para este centro.`" />
            <p>Veja a lista de centros disponíveis: <a href="#test">Clique aqui!</a></p>
          </div>

          <div class="cd-logos pb-6" >
            <div class="cd-logos-content">
              <a href="https://www.ufpb.br/" target="_blank">
                <v-avatar tile size="66" class="my-4 mr-4">
                  <v-img src="../assets/ufpb.png" />
                </v-avatar>
              </a>
              <a href="http://laser.ci.ufpb.br/" target="_blank">
                <v-avatar tile size="66" class="my-4 mr-4">
                  <v-img src="../assets/laser.png" />
                </v-avatar>
              </a>
              <a href="https://linktr.ee/log.ufpb" target="_blank">
                <v-avatar tile size="66" class="my-4 mr-4">
                  <v-img src="../assets/log.png" />
                </v-avatar>
              </a>
              <a href="https://ci.ufpb.br/" target="_blank">
                <v-avatar tile size="66" class="my-4 mr-4">
                  <v-img src="../assets/ci.jpeg" />
                </v-avatar>
              </a>
              <p class="credits pt-4">
                SACI | UFPB | 2023 |
                <a class="credits" href="https://forms.gle/G1zUrtJriExafCDs8" target="_blank">
                  Relatar um problema
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import PaasSolution from "@/components/paas/PaasSolution.vue";
import NotFound from '@/components/NotFound.vue';

import { getPublicSolution } from '@/api/paas';

export default {
  name: "PaasPublica",
  metaInfo: {
    title: 'SACI',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `Alocação de aulas a salas. Pesquise por salas ou turmas!` },
    ]
  },
  components: {
    Loading,
    PaasSolution,
    NotFound,
  },
  data() {
    return {
      solution: [],
      id: '',
      info: {
        centro: '',
        date: '',
        description: '',
      },
      loading: true,
      found: false,
      notFound: false,
      notPublished: false,
      roomParam: -1,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
  },
  created() {
    this.$store.commit("setCurrentComponent", {
      component: "Public",
    });
  },

  methods: {
    //finds room index passed trough param
    findRoomIndex(room) {
      //create a array of room names, ex: {bloco: 'CI', sala: '101'} -> 'ci-101'
      let roomList = this.solution.solution.map((el) => `${el.bloco}-${el.nome}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\W_]+/g, '-').toLowerCase().replace(/^-+|-+$/g, ''));
      //set index found
      this.roomParam = roomList.findIndex(e => e == room.toLocaleLowerCase());
    },
    goHome() {
      this.$router.replace({ name: "Home" });
    }
  },

  async mounted() {

    let center = this.$route.params.centro;
    this.id = center;
    let room = this.$route.query.sala;

    //get solution on database
    let res = await getPublicSolution(center, room);

    switch (res.status) {
      //if is published
      case "published":
        //save the solution
        this.solution = res.solution;
        this.info.centro = res.centro;
        this.info.description = res.description;
        this.info.date = res.date;
        //id room param, find index
        if (room)
          this.findRoomIndex(room)
        //close loading screem
        this.loading = false;
        this.found = true;
        break
      //if academic center exists, but there is no solution
      case "not-published":

        this.info.centro = res.centro;
        this.info.description = res.description;
        this.info.date = res.date;


        this.loading = false;
        this.notPublished = true;
        break
      //if academic center is not found
      default:
        this.loading = false;
        this.notFound = true;
        break
    }

  }
};
</script>

<style scoped>
.main {
  
  /* background: url('../assets/saci-pattern-opacity.png'), linear-gradient(50deg, rgba(71,118,230,1) 0%, rgba(142,84,233,1) 100%);; */
  /* background: url('../assets/saci-pattern-opacity.png'), linear-gradient(135deg, #1491ff, #0346b6); */
  /* background: rgb(71,118,230); */
  /* background: linear-gradient(50deg, rgba(71,118,230,1) 0%, rgba(142,84,233,1) 100%); */
  background: linear-gradient(135deg, #1491ff, #0346b6);
  min-height: 100vh;
}

.content {
  margin: 0 auto;
  max-width: 1800px;
}

.solution {
  padding: 0;
  border-radius: 30px 30px 0 0;
  margin: 0 10px;
  min-height: 90vh;
  background-color: white;
}

.title {
  margin-left: 15px;
  color: white;
}

.center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.all-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.cd-logos {
  bottom: 0;
  text-align: center;
  z-index: 4;
}

.cd-logos-content {
  max-width: 1920px;
  margin: 0 auto;
}

.credits {
  color: #7a7a7a;
  font-size: 14px;
  margin-bottom: 0;
}
</style>